import { Avatar, Badge, Button, Card, Group, Image, SimpleGrid, Text } from '@mantine/core';
import React from 'react'


export default function OverviewGrid(props) {
  const categoryCards = props.categoryData.map((category) => (
    <Card key={category.id} shadow="sm" p="lg" radius="md" withBorder>
      <Card.Section>
        <Image
          src="https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80"
          height={130}
          alt="Norway"
        />
      </Card.Section>

      <Group position="apart" mt="md" mb="xs">
        <Text weight={500}>{category.name}</Text>
        <Badge color="green" variant="light">
          Completed
        </Badge>
      </Group>

      <Group>
        <Avatar color="yellow" size="sm">
        </Avatar>
        <Text size="xs">John Walters</Text>
      </Group>
      <Group>
        <Avatar color="gray" size="sm">
        </Avatar>
        <Text size="xs">John Walters</Text>
      </Group>
      <Group>
        <Avatar color="brown" size="sm">
        </Avatar>
        <Text size="xs">John Walters</Text>
      </Group>

      <Button variant="light" color="blue" fullWidth mt="md" radius="md">
        See all participants
      </Button>
    </Card>
  ));

  return (
    <SimpleGrid
      cols={4}
      breakpoints={[
        { maxWidth: 980, cols: 3, spacing: 'md' },
        { maxWidth: 755, cols: 2, spacing: 'sm' },
        { maxWidth: 600, cols: 1, spacing: 'sm' },
      ]}
    >
      {categoryCards}
    </SimpleGrid>
  );
}