import * as React from "react"
import Layout from "../components/layout";
import OverviewGrid from "../components/overviewGrid";

const categoryData = [
  { id: 0, name: "Calculus 1" },
  { id: 1, name: "Calculus 2" },
  { id: 2, name: "Calculus 3" },
  { id: 3, name: "Linear Algebra" },
  { id: 4, name: "Geometry" }
];

const IndexPage = (props) => {
  return (
    <Layout>
      <OverviewGrid categoryData={categoryData} />
    </Layout>
  );
}

export default IndexPage

export const Head = () => <title>School Event Scores</title>
